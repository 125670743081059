import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import HeaderWhite from "../components/headerWhite"
import { Display, Lead } from "../components/typography"
import { Container, Row, Col } from "react-grid-system"

const Contacts = ({ location }) => {
  return (
    <Layout>
      <SEO title="Contatti" />
      <HeaderWhite />
      <CustomContainer>
        <Row justify="center">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Display align="center">Contattaci</Display>
            <Lead align="center">
              Compila la form per contattare un consulente MULTI
            </Lead>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={12} sm={12} md={9} lg={6}>
            <ContactForm email={location.state ? location.state.email : null} />
          </Col>
        </Row>
      </CustomContainer>
    </Layout>
  )
}

const CustomContainer = styled(Container)`
  margin-top: 4rem;
  h1 {
    margin-bottom: 1rem;
  }
  & > * {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Contacts
